<template>
  <div class="contractDataSummarization">
    <div>
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="80px"
        size="small"
        class="flex"
      >
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="主体">
              <el-select
                style="width: 100%"
                v-model="searchForm.entityId"
                :remote-method="entityRemoteMethod"
                :loading="entityLoading"
                placeholder="请输入关键词"
                filterable
                remote
                clearable
                @change="initData"
                @clear='onEntityClear'
              >
                <el-option
                  v-for="item in entityList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="一级商家">
              <el-select
                style="width: 100%"
                v-model="searchForm.seniorId"
                :remote-method="seniorRemoteMethod"
                :loading="seniorLoading"
                placeholder="请输入关键词"
                clearable
                filterable
                remote
                @change="initData"
                @clear='onSeniorClear'
              >
                <el-option
                  v-for="item in seniorList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="二级商家">
              <el-select
                style="width: 100%"
                v-model="searchForm.businessId"
                :remote-method="businessRemoteMethod"
                :loading="businessLoading"
                placeholder="请输入关键词"
                filterable
                remote
                clearable
                @change="initData"
                @clear='onBusinessClear'
              >
                <el-option
                  v-for="item in businessList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="签约日期">
              <el-date-picker
                style="width: 100%"
                v-model="collect_date"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="setDateRange"
                @change="initData"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="ml_20">
          <el-button type="primary" size="small" @click="initData">查询</el-button>
        </div>
      </el-form>
    </div>
    <div class="auth-table">
      <el-table
        :data="tableData"
        header-cell-class-name="table_header"
        border
        v-loading="loading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, .6)"
      >
        <el-table-column prop="billCount" label="放款账单数" />
        <el-table-column prop="billRepaymentPrincipal" label="放款总本金" />
        <el-table-column prop="billSettleCount" label="其中已完结" />
        <el-table-column prop="billRefundCount" label="其中已退款" />
        <!-- <el-table-column prop="billTakeEffectCount" label="生效账单数" /> -->
        <el-table-column prop="billRepaidPrincipalAmount" label="已还本金" />
        <el-table-column prop="billRemainPrincipalAmount" label="剩余未还本金" />
      </el-table>
    </div>
  </div>
</template>

<script>
import { selectBusiness, selectEntity, selectSenior } from "@/api/selectfun.js";
import { billSummaryDetail } from "@/api/dataSummarization.js";
export default {
  name: "contractDataSummarization",
  data() {
    return {
      tableData: [],
      searchForm: {
        seniorId: "",
        entityId: "",
        businessId: "",
        startTime: "",
        endTime: "",
      },
      seniorList: [],
      seniorLoading: false,
      entityList: [],
      entityLoading:false,
      businessList: [],
      businessLoading:false,
      collect_date: [],
      loading:false,
      setDateRange: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
    };
  },

  mounted() {
    this.setSelectTime();
    this.initData();
  },

  methods: {
    async initData() {
      if (this.collect_date && this.collect_date.length == 2) {
        this.searchForm.startTime = this.collect_date[0] + " " + "00:00:00";
        this.searchForm.endTime   = this.collect_date[1] + " " + "23:59:59";
      } else {
        this.searchForm.startTime = "";
        this.searchForm.endTime   = "";
      }
      this.loading = true
      const { code, data } = await billSummaryDetail(this.searchForm);
      this.loading = false
      if (code == 200) {
        this.tableData = data
      }
    },
    businessRemoteMethod(query){
      if(query != ''){
        this.businessLoading = true
        setTimeout(()=>{
          this.businessLoading = false
          this.getSelectBusiness(query)
        },200)
      } else {
        this.businessList = [];
      }
    },
    onBusinessClear(){
      this.searchForm.businessId = ''
      this.businessList = [];
    },
    async getSelectBusiness(query) {
      const param = {
        business_name : query
      }
      const { code, data } = await selectBusiness(param);
      if (code == 200) {
        this.businessList = data.map((item) => {
          item.value = item.business_id;
          item.label = item.full_name;
          return item;
        });
      }
    },
    entityRemoteMethod(query) {
      if (query != "") {
        this.entityLoading = true;
        setTimeout(() => {
          this.entityLoading = false;
          this.getSelectEntity(query);
        }, 200);
      } else {
        this.entityList = [];
      }
    },
    onEntityClear(){
      this.searchForm.entityId = ''
      this.entityList = [];
    },
    async getSelectEntity(query) {
      const param = {
        entity_name:query
      }
      const { code, data } = await selectEntity(param);
      if (code == 200) {
        this.entityList = data.map((item) => {
          item.value = item.entity_id;
          item.label = item.entity_name;
          return item;
        });
      }
    },
    seniorRemoteMethod(query) {
      if (query != "") {
        this.seniorLoading = true;
        setTimeout(() => {
          this.seniorLoading = false;
          this.getSelectSenior(query);
        }, 200);
      } else {
        this.seniorList = [];
      }
    },
    onSeniorClear(){
      this.searchForm.seniorId = ''
      this.seniorList = [];
    },
    async getSelectSenior(query) {
      const param = {
        senior_name:query
      }
      const { code, data } = await selectSenior(param);
      if (code == 200) {
        this.seniorList = data.map((item) => {
          item.value = item.senior_id;
          item.label = item.senior_name;
          return item;
        });
      }
    },
    setSelectTime() {
      var date = new Date();
      var y = date.getFullYear();
      var m =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

      var start_day = y + "-" + m + "-" + "01";
      var end_day = `${y}-${m}-${d}`;

      this.collect_date = [start_day, end_day];
    },

  },
};
</script>

<style lang="scss" scoped>
.contractDataSummarization {
  .auth-table {
    /deep/ .table_header {
      background-color: #f8f8f9 !important;
      color: #515a6e !important;
    }
  }
}
</style>